* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
  width: 100%;
  font:
    500 1.6rem 'Montserrat',
    sans-serif;
  font-size: 62.5%;
  overflow-x: hidden;
  background-color: #f7f8fc;
  -webkit-font-smoothing: antialiased;
  overflow-y: hidden;
}

:focus {
  outline: 0;
}

button,
div,
input {
  font:
    14px 'Montserrat',
    sans-serif;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

button {
  outline: 0;
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #d0a967;
}

::-webkit-scrollbar-thumb:hover {
  background: #d0a967;
}

input:-webkit-autofill,
input:-webkit-autofill:active,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover {
  box-shadow: 0 0 0 30px #fff inset !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #707070 !important;
}

::-webkit-scrollbar {
  width: 10px;
}

.main-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  min-height: 100vh;
}

.page-header {
  position: sticky;
  width: 100%;
  z-index: 20;
  top: 0;
  left: 0;
}

.page-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.page-footer {
  margin-top: auto;
}
