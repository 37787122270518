#cookies-accept-modal {
  position: fixed;
  width: 90%;
  margin: auto;
  left: 0;
  right: 0;
  background-color: #fff;
  padding: 20px;
  z-index: 100;
  font-family: 'Rubik', sans-serif;
  bottom: 10px;
  border-radius: 5px;
  color: #707070;
  text-align: center;
  font-size: 14px;
  box-shadow: #4a5369 -1px 1px 20px;
}

.cookies-accept-modal--hidden {
  display: none !important;
}

.cookies-accept-modal__message {
  margin-bottom: 20px;
}

.cookies-accept-modal__btn {
  border: solid 1px #004f92;
  border-radius: 5px;
  padding: 10px 0;
  width: 100%;
}

.cookies-accept-modal__btn--accept {
  background: #004f92;
  color: #fff;
  margin-bottom: 1em;
}

.cookies-accept-modal__btn--read-more {
  background: #fff;
  color: #004f92;
}

@media only screen and (min-width: 481px) {
  #cookies-accept-modal {
    bottom: 10%;
  }
  .cookies-accept-modal__buttons {
    display: flex;
    justify-content: space-around;
  }
  .cookies-accept-modal__btn {
    padding: 5px 0;
    width: 120px;
  }
}

@media only screen and (min-width: 905px) {
  #cookies-accept-modal {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 20px;
    font-size: 16px;
  }
  .cookies-accept-modal__message {
    padding-right: 30px;
    margin-bottom: 0;
  }
  .cookies-accept-modal__buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .cookies-accept-modal__btn {
    margin-bottom: 0;
  }
  .cookies-accept-modal__btn--accept {
    margin-right: 15px;
  }
}
