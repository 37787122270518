#toast-container {
  width: 100dvw;
}

#toast-container > div {
  width: 100%;
  max-width: 1200px;

  border-radius: unset;
  border-style: solid;
  border-radius: 4px;
  border-width: 1px;
  font-family: 'Montserrat', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background-color: white;
  color: #39393b;
  left: 50%;
  transform: translateX(-50%);

  position: relative;
  background-image: none !important;
  height: 40px;
  opacity: 1;

  display: flex;
  align-items: center;
}

#toast-container > div::after {
  content: '';
  position: absolute;

  top: 0px;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: 48px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px;
}

#toast-container > .toast-error {
  background-image: none !important;
}

.toast-error {
  border-color: #d41d18;
  background-color: #d41d18;
}

.toast-error::after {
  background-color: #d41d18;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE0LjM5NTUgOS41OTQ5MUw5LjYwMzUyIDE0LjM4NjkiIHN0cm9rZT0iI2ZmZmZmZiIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMTQuMzk3IDE0LjM4OThMOS42MDEwMSA5LjU5MjgiIHN0cm9rZT0iI2ZmZmZmZiIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTE2LjMzNDUgMi43NTAyMUg3LjY2NTQ5QzQuNjQ0NDkgMi43NTAyMSAyLjc1MDQ5IDQuODg5MjEgMi43NTA0OSA3LjkxNjIxVjE2LjA4NDJDMi43NTA0OSAxOS4xMTEyIDQuNjM1NDkgMjEuMjUwMiA3LjY2NTQ5IDIxLjI1MDJIMTYuMzMzNUMxOS4zNjQ1IDIxLjI1MDIgMjEuMjUwNSAxOS4xMTEyIDIxLjI1MDUgMTYuMDg0MlY3LjkxNjIxQzIxLjI1MDUgNC44ODkyMSAxOS4zNjQ1IDIuNzUwMjEgMTYuMzM0NSAyLjc1MDIxWiIgc3Ryb2tlPSIjZmZmZmZmIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=');
}

.toast-error .toast-progress {
  background-color: #d41d18;
}

.toast-success {
  border-color: #3eb49d;
  background-color: #3eb49d;
}

.toast-success::after {
  background-color: #3eb49d;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yLjc1MDI0IDEyQzIuNzUwMjQgMTcuMTA4IDYuODkxMjQgMjEuMjUgMTIuMDAwMiAyMS4yNUMxNy4xMDgyIDIxLjI1IDIxLjI1MDIgMTcuMTA4IDIxLjI1MDIgMTJDMjEuMjUwMiA2Ljg5MiAxNy4xMDgyIDIuNzUgMTIuMDAwMiAyLjc1QzYuODkxMjQgMi43NSAyLjc1MDI0IDYuODkyIDIuNzUwMjQgMTJaIiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTguNTI5MDUgMTAuNTU3N0wxMi4wMDAxIDE0LjA0MzdMMTUuNDcxMSAxMC41NTc3IiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==');
}

.toast-success .toast-progress {
  background-color: #3eb49d;
}

.toast-warning {
  border-color: #faaa2f;
  background-color: #faaa2f;
}

.toast-warning::after {
  background-color: #faaa2f;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTExLjI1MDMgMTYuMjY5NkMxMS4yNTAzIDE1Ljg1NTYgMTEuNTg2MyAxNS41MTM2IDEyLjAwMDMgMTUuNTEzNkMxMi40MTQzIDE1LjUxMzYgMTIuNzUwMyAxNS44NDQ2IDEyLjc1MDMgMTYuMjU4NlYxNi4yNjk2QzEyLjc1MDMgMTYuNjgzNiAxMi40MTQzIDE3LjAxOTYgMTIuMDAwMyAxNy4wMTk2QzExLjU4NjMgMTcuMDE5NiAxMS4yNTAzIDE2LjY4MzYgMTEuMjUwMyAxNi4yNjk2WiIgZmlsbD0iI2ZmZmZmZiIvPgo8cGF0aCBkPSJNNC43OTU3MiAyMC40MTc5SDE5LjI3NzdDMjAuNDI0NyAyMC4zNTc5IDIxLjMwNjcgMTkuMzgwOSAyMS4yNDc3IDE4LjIzMzlDMjEuMjM0NyAxOC4wMDE5IDIxLjE4NDcgMTcuNzcyOSAyMS4wOTY3IDE3LjU1ODlMMTMuODE5NyA0LjgyMjg5QzEzLjI2MzcgMy44MTc4OSAxMS45OTg3IDMuNDUzODkgMTAuOTkzNyA0LjAxMDg5QzEwLjY1MTcgNC4xOTg4OSAxMC4zNjk3IDQuNDgwODkgMTAuMTgwNyA0LjgyMjg5TDIuOTAzNzIgMTcuNTU4OUMyLjQ3MTcyIDE4LjYyMjkgMi45ODM3MiAxOS44MzQ5IDQuMDQ2NzIgMjAuMjY3OUM0LjI2MjcyIDIwLjM1NDkgNC40OTA3MiAyMC40MDQ5IDQuNzIyNzIgMjAuNDE3OSIgc3Ryb2tlPSIjZmZmZmZmIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik0xMS45ODk5IDEzLjM5NjFWMTAuMjk2MSIgc3Ryb2tlPSIjZmZmZmZmIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=');
}

.toast-warning .toast-progress {
  background-color: #faaa2f;
}

.toast-message {
  margin-left: 8px;
}

.toast-progress {
  opacity: 1;
}
